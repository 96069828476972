export function removeEmptyObjects(data) {
  Object.keys(data).forEach(key => {
    if (data[key] && typeof data[key] === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      const obj = data[key]
      const filtered = Object.keys(obj)
        .filter(k => obj[k] != null)
        .reduce((a, k) => ({ ...a, [k]: obj[k] }), {})

      // eslint-disable-next-line no-param-reassign
      if (Object.keys(filtered).length === 0) delete data[key]
    }
  })
}

export function removeUndefinedValues(data) {
  Object.keys(data).forEach(key => {
    if (data[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete data[key]
    }
  })
}

export function copyExistingProperties(properties, object) {
  const existing = { ...properties }

  Object.keys(properties).forEach(key => {
    if (object[key] && typeof object[key] === 'object') {
      existing[key] = copyExistingProperties(properties[key], object[key])
    } else {
      existing[key] = object[key]
    }
  })

  return existing
}

export const cloneObject = object => JSON.parse(JSON.stringify(object))
