<template>
  <b-card no-body>
    <card-header-filters
      title="Seasons"
      @add="handleAdd"
    />

    <!-- Seasons List -->
    <table-list
      primary-key="id"
      show-empty
      :items="seasons"
      :fields="fields"
      :busy="isLoading"
      :show-delete="false"
      item-name="season"
      @link-clicked="$router.push({ name: 'event.season', params: { season: $event.id } })"
    />

    <!-- Add New Season -->
    <Modal
      v-model="modalAdd"
      title="Add Season"
      :submit-buttons="{
        save: { text: 'Ok' },
        cancel: false,
      }"
      @save="modalAdd = false"
    >
      <template #details>
        To add a season, please call sales at: <b>973-404-0048</b>
      </template>
    </Modal>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import TableList from '@/components/TableList.vue'
import Modal from '@/components/modals/Modal.vue'

import { mapState } from 'vuex'
import useFlash from '@/composables/useFlash'
import { formatDateString } from '@core/utils/filter'
import { userUsedMasterPassword } from '@/auth/utils'

const { flashError } = useFlash()

export default {
  components: {
    Modal,
    BCard,
    CardHeaderFilters,
    TableList,
  },
  data() {
    return {
      isLoading: true,
      modalAdd: false,
      fields: [
        'name',
        {
          key: 'date',
          formatter: (value, key, item) => `${formatDateString(item.start_date)} - ${formatDateString(item.end_date)}`,
        },
        // 'playing_periods',
        // 'regulation_length',
        // 'overtime_length',
      ],
    }
  },
  computed: {
    ...mapState('event/seasons', ['seasons']),
  },
  async created() {
    this.isLoading = true

    try {
      await this.$store.dispatch('event/seasons/getSeasons', { event: this.$route.params.event })
    } catch (error) {
      flashError(error)
      this.$Progress.fail()
    }

    this.isLoading = false
    this.$Progress.finish()
  },
  methods: {
    handleAdd() {
      if (userUsedMasterPassword()) {
        this.$router.push({ name: 'event.season' })
        return
      }

      this.modalAdd = true
    },
  },
}
</script>

<style scoped>

</style>
